import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {navigate} from 'gatsby';

import * as AppContext from '../../AppContext';
import {BellOutlined, LeftOutlined} from '../../Widgets/Icon';
import ActivityDetailDisplay from '../../Components/Activity.DisplayDetail';
import ActivityTable from '../../Components/Activity.Table';

const queryString = require('query-string');

function ActivityMgrPage(props) {
  const app = React.useContext(AppContext.Context);
  const {location} = props;
  const params = queryString.parse(location.search) || {};

  const [records, setRecords] = React.useState(null);
  const [selectedRecord, setSelectedRecord] = React.useState(null);

  const fetchActivityList = React.useCallback(async () => {
    app.actions.setLoading(true);
    try {
      let records = await app.actions.fetchActivityList();
      records = records.filter((record) => record.status !== 'draft');
      setRecords(records);
    } catch (ex) {
      console.warn('fetch activity list error');
    }

    app.actions.setLoading(false);
  }, [app.actions]);

  React.useEffect(() => {
    fetchActivityList();
    if (!selectedRecord) {
      navigate('/activity');
    }
  }, [fetchActivityList, selectedRecord]);

  return (
    <Wrapper>
      <div className="row-container">
        {!selectedRecord ? (
          <div className="header">
            <h3 className="title">活動列表</h3>
          </div>
        ) : (
          <div className="header">
            <Button
              shape="circle"
              icon={<LeftOutlined />}
              onClick={() => {
                navigate('/activity');
                setSelectedRecord(null);
              }}
            />
          </div>
        )}
        <div className="notify">
          <Button
            type="outline"
            onClick={() => app.actions.setMemberNotifications([])}>
            <BellOutlined color="rgba(0, 0, 0, 0.65)" />
          </Button>
          <div className="badge" />
        </div>
      </div>

      <div className="column-container">
        {!selectedRecord ? (
          <ActivityTable
            records={records}
            goToDetail={(record) => {
              navigate(`/activity?id=${record.id}`);
              setSelectedRecord(record);
            }}
          />
        ) : (
          <ActivityDetailDisplay record={selectedRecord} />
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 60px 40px;
  background-color: white;

  & > .row-container {
    margin-bottom: 15px;
    display: flex;

    & > .header {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-right: 20px;
      margin-bottom: 20px;

      & > .title {
        font-size: 24px;
        color: #4a4a4a;
        line-height: 22px;
      }
    }

    & > .notify {
      position: relative;

      & > .badge {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #ec8e25;
        top: -4px;
        right: -4px;
      }
    }
  }

  & > .column-container {
  }
`;
export default ActivityMgrPage;
